import React from "react";
import { Modal, Button } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";

const ConfirmCommentActionModal = ({callback}) => {
  const { t } = useTranslation();

  return (
    <Modal
      bottomSheet={false}
      fixedFooter={false}
      header={t("actions.confirm_delete_comment_title")}
      open={false}
      className="delete-modal"
      trigger={
        <div className="comment-dropdown-option"> {t("general.delete")} </div>
      }
      actions={[
          <div className="div-buttons" key="buttons-container">
            <Button className="btn btn-darkest" modal="close">
              <div>
                <span>{t("general.cancel")}</span>
              </div>
            </Button>
            <Button onClick={() => callback()} className="btn btn-purple">
              <div>
                <span>{t("general.delete")}</span>
              </div>
            </Button>
          </div>
      ]}>
      {t("actions.confirm_delete_comment_body")}
    </Modal>
  );
};

export default ConfirmCommentActionModal;
