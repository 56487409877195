import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';
import { getContentResponse } from '../helpers/getContentResponse';

const IAL_ACTION_USERS_URL = IAL_API_URL + '/problem-solving/action-responsibles';



export async function getActionUsers(actionId, authenticationData) {
  try {
    const config = getHeaders(authenticationData?.accessToken);
    const url = `${IAL_ACTION_USERS_URL}/${actionId}`;
    const response = await axios.get(url, config);

    return getContentResponse(response);
  } catch (e) {
    return false;
  }
};
