import React, { useState } from "react";
import { useTranslation } from "@dws/dws-profile";
import { Row, Col, Icon, Textarea, Loading, Button } from "@dws/dws-styleguide";
import { getAccount } from "../../helpers/auth";
import { postActionComment } from "../../hooks/postActionComment";

const ActionDetailNewComment = ({
  refreshData,
  actionCode,
  username,
  hidden,
  setIsNew,
  authenticationData,
}) => {
  const { t } = useTranslation();
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);

  const createComment = async () => {
    setLoading(true);
    const payload = {
      cod_action: actionCode,
      str_text: commentText,
    };

    const response = await postActionComment(payload, authenticationData);
    handleResponse(response, "saving_comment");
  };

  const handleResponse = async (response, message) => {
    const { username: email } = getAccount();
    const isSuccess = response.type === "success";
    if (isSuccess) {
      const commentId = response.content?.cod_action_comment;
      const newComment = {
        commentCode: commentId,
        text: commentText,
        username: username,
        email: email,
        insertDate: new Date().toISOString(),
      };
      await refreshData(newComment);
      handleCancel();
    }

    showToast(isSuccess, message);
    setLoading(false);
  };

  const showToast = (isSuccess, message) => {
    const toastType = isSuccess ? 'success' : 'error';
    M.toast({
      html: t(`actions.${toastType}_${message}`),
      classes: `toast-${toastType}`,
      displayLength: 4000,
    });
  };

  const handleCancel = () => {
    if (loading) return;
    setCommentText("");
    setIsNew(false);
  };

  if (hidden) return null;

  return (
    <div className="action-detail-comment is-me">
      <div className="action-detail-comment-content">
        <Row className="user-row">
          {/* TODO: add user avatar url */}
          <img className="user-avatar"></img>
          <Col className="comment-header">
            <div className="ch-username valign-wrapper">{username}</div>
            <Icon tiny onClick={() => handleCancel()} className="icon">
              close
            </Icon>
          </Col>
        </Row>
        <Row className="mb-2 text-row">
          {loading ? (
            <Loading className="loading" />
          ) : (
            <Textarea
              id="new-comment-input"
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
              value={commentText}
            />
          )}
        </Row>
        <Row className="new-comment-button-wrapper">
          <Button
            disabled={loading}
            onClick={() => handleCancel()}
            className="btn btn-darkest">
            {t("general.cancel")}
          </Button>
          <Button
            disabled={loading}
            onClick={() => createComment()}
            className="btn btn-purple">
            {t("general.create")}
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default ActionDetailNewComment;
