import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';

const IAL_DELETE_ACTION_COMMENT_URL = IAL_API_URL + '/problem-solving/action-comment';

export async function deleteActionComment(payload, authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);

  const URL = IAL_DELETE_ACTION_COMMENT_URL + '/' + payload.cod_action_comment;

  try {
    const response = await axios.delete(URL, config);

    return response.data;
  } catch (e) {
    return false;
  };
};
