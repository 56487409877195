export function getHeaders(accessToken) {
    return {
        headers: {
        "Accept-Language": localStorage.getItem("i18nextLng") ?? "en",
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
    };
};

export function getAccount() {
    const accountString = localStorage.getItem("account");
    return accountString ? JSON.parse(accountString) : null;
};
