import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "@dws/dws-profile";
import {
  Row,
  Col,
  Loading,
  DatePicker,
  DWSMultiSelect,
  DWSTextArea,
  DWSInput,
} from "@dws/dws-styleguide";
import ActionDetailStatus from "./ActionDetailStatus";
import { getActionUsers } from "../../hooks/getActionUsers";
import { getActionDepartments } from "../../hooks/getActionDepartments";
import { getActionIndicators } from "../../hooks/getActionIndicators";
import { getActionMeetings } from "../../hooks/getActionMeetings";
import { putActionDetails } from "../../hooks/updateActionDetails";

async function getActionOptions(
  actionCode,
  setMeetingsOptions,
  setDepartmentsOptions,
  setResponsibleOptions,
  setIndicatorsOptions,
  authenticationData
) {
  setMeetingsOptions(() => []);
  setDepartmentsOptions(() => []);
  setResponsibleOptions(() => []);
  setIndicatorsOptions(() => []);
  try {
    const [
      actionUsersList,
      actionDepartmentsList,
      actionIndicatorsList,
      actionMeetingsList,
    ] = await Promise.all([
      getActionUsers(actionCode, authenticationData),
      getActionDepartments(actionCode, authenticationData),
      getActionIndicators(actionCode, authenticationData),
      getActionMeetings(authenticationData),
    ]);

    const meetingList = actionMeetingsList?.map((meeting) => {
      return {
        codeMeetings: meeting.cod_meetings,
        name: meeting.str_name,
        };
    });
    setMeetingsOptions(() => meetingList);

    const departmentList = actionDepartmentsList?.map((department) => {
      return {
        departmentCode: department.cod_department,
        departmentName: department.str_department_name,
      };
    });
    setDepartmentsOptions(() => departmentList);

    const userList = actionUsersList?.map((user) => {
      return {
        responsibleCode: user.cod_user,
        responsibleName: user.str_name,
      };
    });
    setResponsibleOptions(() => userList);

    const indicatorList = actionIndicatorsList.map((indicator) => {
      return {
        indicatorCode: indicator.cod_indicator,
        indicatorName: indicator.str_indicator_name,
        };
    });
    setIndicatorsOptions(() => indicatorList);
  } catch (error) {
    console.log(error);
  }
};

function convertDueDateToISO(dueDateObj) {
  const [year, month, day] = dueDateObj.split("/").reverse();
  return `${year}-${month}-${day}`;
};

function isFormValid(formData) {
  const requiredFields = [
    "actionDescription",
    "actionResponsible",
    "actionDueDate",
    "actionDepartment",
  ];

  for (let [key, value] of formData.entries()) {
    if (
      requiredFields.includes(key) &&
      (value == null || value.trim() === "" || value.length === 0)
    ) {
      return false;
    };
  };

  return true;
};

function getUpdateActionPayloadToIAL(action, formData) {
  const payload = {
    cod_action: action?.code,
    str_description: formData.get("actionDescription"),
    dat_due: convertDueDateToISO(formData.get("actionDueDate")),
    cod_department: Number(formData.get("actionDepartment")),
    str_origin: formData.get("actionOrigin"),
    str_root_cause: formData.get("actionRootCause"),
    cod_responsible: Number(formData.get("actionResponsible")),
    arr_cod_meetings: formData.get("actionMeetings") ? formData.get("actionMeetings").split(",").map(Number) : [],
    arr_cod_indicators: formData.get("actionIndicators") ? formData.get("actionIndicators").split(",").map(Number) : [],
  };

  return payload;
};

let debounce;
function onFormChanged(_e, formRef) {
  clearTimeout(debounce);

  debounce = setTimeout(() => {
    formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
  }, 2000);
};

function onFormSubmit(e, action, t, authenticationData) {
  const formData = new FormData(e.target);

  let valid = isFormValid(formData);

  if (!valid) {
    M.toast({
      html: t("actions.required_fields_missing"),
      classes: "toast-warning",
      displayLength: 4000,
    });
    return;
  };

  const payload = getUpdateActionPayloadToIAL(action, formData);

  putActionDetails(payload, authenticationData)
    .then((response) => {
      if (response.status === 200) {
        action.description = payload.description;
        action.dueDate = payload.dueDate;
        action.departmentCode = payload.departmentCode;
        action.origin = payload.origin;
        action.rootCause = payload.rootCause;
        action.responsibleCode = payload.responsibleCode;
        action.meetingCodes = payload.meetingCodes;
        action.indicatorCodes = payload.indicatorCodes;

        return M.toast({
          html: t("actions.action_update_success"),
          classes: "toast-success",
          displayLength: 4000,
        });
      };
    })
    .catch((error) => {
      M.toast({
        html: t("actions.action_update_error"),
        classes: "toast-warning",
        displayLength: 4000,
      });
      console.log(error);
    });
};

const ActionDetailBody = ({ action, updateActionStatus, loading, authenticationData }) => {
  const actionResponsible = [action.responsibleCode];
  const actionDepartment = [parseInt(action.departmentCode)];
  const actionOrigin = action.origin;
  const actionRootCause = action.rootCause;
  const { interactionLogActionsIndicators: indicators } = action;
  const { t } = useTranslation();

  // Options for selects
  const [meetingsOptions, setMeetingsOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [responsibleOptions, setResponsibleOptions] = useState([]);
  const [indicatorsOptions, setIndicatorsOptions] = useState([]);

  // Action Properties
  const actionDescription = action.description ?? "";
  const actionDueDate = new Date(action.dueDate);
  const actionIndicators = indicators.map(
    (indicator) => indicator.indicatorCode
  );

  const actionMeetings = action.associatedMeetings.map(
    (meeting) => meeting.meetingCode
  );

  useEffect(() => {
    formRef.current.reset();

    if (action) {
      getActionOptions(
        action.code,
        setMeetingsOptions,
        setDepartmentsOptions,
        setResponsibleOptions,
        setIndicatorsOptions,
        authenticationData
      );
    }
  }, [action]);

  const formRef = useRef(null);

  if (loading) return <Loading />;
  if (!action) return null;

  return (
    <form
      className="action-detail-main-info"
      onChange={(e) => onFormChanged(e, formRef)}
      onSubmit={(e) => onFormSubmit(e, action, t, authenticationData)}
      ref={formRef}
    >
      <div className="action-detail-main-info" key={action.code}>
        <Row className="info-due-date">
          <Col m={12} s={12}>
            <ActionDetailStatus
              action={action}
              updateActionStatus={updateActionStatus}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col m={6} s={12}>
            <div className="info-description">
              <div className="info-description-title">
                {t("actions.action_description")}
              </div>
              <div className="info-description-body">
                <DWSTextArea
                  name="actionDescription"
                  cols={30}
                  rows={8}
                  placeholder={t("actions.action_description")}
                  value={actionDescription}
                  emptyAllowed={false}
                />
              </div>
            </div>
          </Col>
          <Col m={6} s={12}>
            <DWSMultiSelect
              itemLabel="name"
              itemValue="codeMeetings"
              name="actionMeetings"
              options={meetingsOptions}
              label={t("actions.meeting_name")}
              className="dws-multiselect"
              truncate={true}
              selectedValues={actionMeetings}
              onChange={(e) => onFormChanged(e, formRef)}
              isMultiple={true}
              minValues={1}
            ></DWSMultiSelect>
          </Col>
          <Col m={6} s={12}>
            <DWSMultiSelect
              itemLabel="responsibleName"
              itemValue="responsibleCode"
              name="actionResponsible"
              options={responsibleOptions}
              label={t("actions.responsible")}
              className="dws-multiselect"
              truncate={true}
              selectedValues={actionResponsible}
              onChange={(e) => onFormChanged(e, formRef)}
              isMultiple={false}
              minValues={1}
            ></DWSMultiSelect>
          </Col>
        </Row>

        <Row>
          <Col m={2} s={12}>
            <DatePicker
              name="actionDueDate"
              label={t("actions.due_date")}
              value={actionDueDate}
              onDateChange={(e) => onFormChanged(e, formRef)}
            ></DatePicker>
          </Col>
          <Col m={4} s={12}>
            <DWSMultiSelect
              itemLabel="indicatorName"
              itemValue="indicatorCode"
              name="actionIndicators"
              options={indicatorsOptions}
              label={t("actions.indicators")}
              className="dws-multiselect"
              truncate={true}
              selectedValues={actionIndicators}
              onChange={(e) => onFormChanged(e, formRef)}
              isMultiple={true}
            ></DWSMultiSelect>
          </Col>
          <Col m={6} s={12}>
            <DWSMultiSelect
              itemLabel="departmentName"
              itemValue="departmentCode"
              name="actionDepartment"
              options={departmentsOptions}
              label={t("actions.department")}
              className="dws-multiselect"
              truncate={true}
              selectedValues={actionDepartment}
              onChange={(e) => onFormChanged(e, formRef)}
              isMultiple={false}
              minValues={1}
            ></DWSMultiSelect>
          </Col>
        </Row>
        <Row>
          <Col m={6} s={12} className="ta-default">
            <DWSInput
              name="actionOrigin"
              label={t("actions.action_origin")}
              truncate={true}
              value={actionOrigin}
              onChange={(e) => onFormChanged(e, formRef)}
              emptyAllowed={true}
            />
          </Col>
          <Col m={6} s={12} className="ta-default">
            <DWSInput
              name="actionRootCause"
              label={t("actions.root_cause")}
              truncate={true}
              value={actionRootCause}
              onChange={(e) => onFormChanged(e, formRef)}
              emptyAllowed={true}
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default ActionDetailBody;
