import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';

const IAL_ACTION_DETAILS_UPDATE_URL = IAL_API_URL + '/problem-solving/action-details';

export async function putActionDetails(payload, authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);
  try {
    const response = await axios.put(IAL_ACTION_DETAILS_UPDATE_URL, payload, config);
    return response;
  } catch (e) {
    console.log('An error occurred while updating action details: ', e);
    return false;
  }
}
