import React from "react";
import { Link } from "@reach/router";
import { useTranslation } from "@dws/dws-profile";
import { Col, Icon, Button } from "@dws/dws-styleguide";

const ActionDetailHeader = ({
  total,
  actionPos,
  actionCodeBefore,
  actionCodeNext,
}) => {
  const { t } = useTranslation();

  return (
    <div className="action-detail-topbar">
      <Col m={3} className="hide-on-small-only title">
        {t("actions.action_details")}
      </Col>
      <Col m={4} s={3} className="">
        {total
          ? `${actionPos + 1} ${t("general.out_of")} ${total} ${t(
              "actions.actions"
            )}`
          : t("errors.action_count")}
      </Col>
      <Col m={1} s={2} className="right-align">
        <Link to={"/main"}>
          <Button className="btn-transparent">
            <Icon>close</Icon>
          </Button>
        </Link>
      </Col>
    </div>
  );
};

export default ActionDetailHeader;
