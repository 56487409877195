"use strict";
import { useState, useEffect, useRef } from "react";
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';

export function useActionData( id, authenticationData) {
  const reloadRef = useRef(true);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAction = async (id) => {
      try {
        const config = getHeaders(authenticationData?.accessToken);
        const url = `${IAL_API_URL}/meetings/v2/action/${id}/detail`;
        const response = await fetch(url, config);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const action = mapActionFromJSON(data.data.results);
        setAction(action);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setError(e);
        setLoading(false);
      }
    };

    if (reloadRef.current) {
      getAction(id);
      reloadRef.current = false;
    }
  }, [id, reloadRef.current]);

  return { action, loading, error, reloadRef };
}

function mapActionFromJSON(json) {
  return {
    code: json.cod_actions,
    description: json.str_description,
    dueDate: json.dat_due,
    statusCodeDisregardingDeadline: json.cod_status_disregarding_deadline,
    statusDisregardingDeadlineDescription:
      json.str_status_disregarding_deadline_description,
    actionStatusCode: json.cod_actions_status,
    actionStatusDescription: json.str_status_description,
    departmentCode: json.cod_department,
    department: json.str_department,
    origin: json.str_origin,
    responsibleCode: json.cod_responsible,
    responsibleUsername: json.str_responsible_username,
    responsibleAmbevId: json.str_responsible_ambev_id,
    responsibleEmail: json.str_responsible_email,
    rootCause: json.str_root_cause,
    interactionLogActionComments: json.array_json_comments?.map(
      mapActionCommentFromJSON
    ),
    interactionLogActionsIndicators: json.array_json_indicators?.map(
      mapActionIndicatorFromJSON
    ),
    associatedMeetings: json.array_json_meetings?.map(
      mapActionAssociatedMeetingFromJSON
    ),
    canChangeTo: json.array_json_can_change_to?.map(
      mapActionStatusPossibleStateFromJSON
    ),
    daysUntilDueDate:
      new Date(Date.parse(json.dat_due)).getDate() - new Date().getDate(),
  };
}

function mapActionCommentFromJSON(json) {
  return {
    commentCode: json.cod_comment,
    text: json.str_text,
    fileUrl: json.str_url_file,
    fileExtension: json.str_file_extension,
    imageName: json.str_name_image,
    insertDate: json.dat_ins,
    username: json.str_username,
    employeeId: json.employee_id,
    email: json.str_email,
  };
}

function mapActionIndicatorFromJSON(json) {
  return {
    indicatorCode: json.cod_indicator,
    indicator: json.str_indicator,
  };
}

function mapActionAssociatedMeetingFromJSON(json) {
  return {
    meetingCode: json.cod_meetings,
    meetingName: json.str_name,
  };
}

function mapActionStatusPossibleStateFromJSON(json) {
  return {
    statusCode: json.cod_actions_status,
    actionStatusDescription: json.str_status_description,
  };
}
