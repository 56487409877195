import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';
import { getContentResponse } from '../helpers/getContentResponse';

const IAL_ACTION_DEPARTMENTS_URL = IAL_API_URL + '/problem-solving/action-areas';

export async function getActionDepartments(actionId, authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);
  const url = `${IAL_ACTION_DEPARTMENTS_URL}/${actionId}`;
  try {
    const response = await axios.get(url, config);
    return getContentResponse(response);
  } catch (e) {
    return false;
  }
};
