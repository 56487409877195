import React from "react";
import { Icon } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";

const Status = ({ days, label, status, current, disabled, onClick }) => {
  const text = label || StatusLabel(days);

  const TagNegative = () => (
    <div className="tag-container tag-negative">
      <div className="tag-icon-wrapper">
        <Icon className="material-icons-outlined">report</Icon>
      </div>
      <span>{text}</span>
    </div>
  );

  const TagNeutral = () => (
    <div className="tag-container tag-neutral">
      <div className="tag-icon-wrapper">
        <Icon className="material-icons-outlined">warning</Icon>
      </div>
      <span>{text}</span>
    </div>
  );
  const TagAction = () => {
    const { t } = useTranslation();
    return (
      <div
        className={`tag-container tag-action 
  ${label === current ? "current-tag" : ""} 
  ${disabled ? "tag-disabled" : "tag-active"}`}
        onClick={onClick}>
        <span title={disabled ? t("actions.disabled_state") : ""}>{text}</span>
      </div>
    );
  };

  return (
    <div className="tag-container-wrapper">
      {status && <TagAction />}
      {!status && days < 0 && <TagNegative />}
      {!status && days > -1 && <TagNeutral />}
    </div>
  );
};

export const StatusLabel = (days) => {
  const { t } = useTranslation();
  if (days === 0) {
    return t("actions.today");
  } else if (days === -1) {
    return t("actions.late_by_day", { days: Math.abs(days) });
  } else if (days < -1) {
    return t("actions.late_by_days", { days: Math.abs(days) });
  } else if (days === 1) {
    return t("actions.day_left", { days: Math.abs(days) });
  } else if (days > 1) {
    return t("actions.days_left", { days: Math.abs(days) });
  }
};

export default Status;
