import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';

const IAL_EDIT_ACTION_COMMENT_URL = IAL_API_URL + '/problem-solving/action-comment';

export async function editActionComment(payload, authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);
  try {
    const response = await axios.put(IAL_EDIT_ACTION_COMMENT_URL, payload, config);

    return response.data;
  } catch (e) {
    return false;
  };
};