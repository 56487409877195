import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';
import { getContentResponse } from '../helpers/getContentResponse';

const IAL_ACTION_MEETINGS_URL = IAL_API_URL + '/problem-solving/action-meetings';

export async function getActionMeetings (authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);
  try {
    const response = await axios.get(IAL_ACTION_MEETINGS_URL, config);
    return getContentResponse(response);
  } catch (e) {
    return false;
  }
};
