import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';

const IAL_POST_ACTION_COMMENT_URL = IAL_API_URL + '/problem-solving/action-comment';

export async function postActionComment(payload, authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);
  try {
    const response = await axios.post(IAL_POST_ACTION_COMMENT_URL, payload, config);

    return response;
  } catch (e) {
    return false;
  };
};