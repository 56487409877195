import { Router } from '@reach/router';
import { ActionDetailPage } from './components/ActionDetail/ActionDetail';

export default function Root(props) {
  return (
        <Router>
          <ActionDetailPage {...props} path={"/actions/:id"} />
        </Router>
  );
}
