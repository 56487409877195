"use strict";

import React from "react";
import ActionDetailHeader from "./ActionDetailHeader";
import ActionDetailBody from "./ActionDetailBody";
import ActionDetailComments from "./ActionDetailComments";
import { putActionStatus } from "../../hooks/updateActionStatus";
import { AuthenticatedSPA } from "@dws/dws-auth";
import { useActionData } from "../../hooks/useActionData";
import { useTranslation } from "@dws/dws-profile";
import { CLIENT_ID } from "../../config";


async function updateActionStatus(actionCode, actionStatusCode, authenticationData) {
  const payload = {
    cod_action: actionCode,
    cod_action_status: actionStatusCode,
  };
  
  try {
    await putActionStatus(payload, authenticationData);
  } catch (e) {
    console.log("error updating status", e);
  }
}

function handleActionPosition(action) {
  if (!action) return { totalActions: 0, actionPos: 0 };
  const countData = JSON.parse(localStorage.getItem("tabCounts")) || {};
  const sortedActions = countData["sorted-actions"] || [];
  const totalActions = sortedActions.length;
  const actionPos = sortedActions.indexOf(action.code);

  return { totalActions, actionPos };
};

export function ActionDetail({ authenticationData, ...props }) {
  const { t } = useTranslation();
  const [newActionStatus, setNewActionStatus] = React.useState(
    action?.statusCodeDisregardingDeadline
  );

  const { action, error, loading, reloadRef } = useActionData(props?.id, authenticationData);
  const { totalActions, actionPos } = handleActionPosition(action);

  if (error) return <div>error</div>;
  if (loading) return <div>loading</div>;

  function updateActionDetailsWithEffect(actionCode, actionStatusCode) {
    setNewActionStatus(actionStatusCode);
    updateActionStatus(actionCode, actionStatusCode, authenticationData).then(() => {
      reloadRef.current = true;
      return M.toast({
        html: t("actions.action_update_success"),
        classes: "toast-success",
        displayLength: 4000,
      });
    });
  }

  action.statusCodeDisregardingDeadline = newActionStatus;

  return (

      <div className="container-actions container-action-detail">
        <ActionDetailHeader total={totalActions} actionPos={actionPos} />
        <ActionDetailBody
          action={action}
          updateActionStatus={updateActionDetailsWithEffect}
          loading={loading}
          authenticationData={authenticationData}
        />
        <ActionDetailComments action={action} authenticationData={authenticationData} />
      </div>

  );
};

export function ActionDetailPage(props) {
  return (
    <AuthenticatedSPA clientId={CLIENT_ID}>
      <ActionDetail {...props} />
    </AuthenticatedSPA>
  );
}
