import React, { useState } from "react";
import { useTranslation } from "@dws/dws-profile";
import {
  Row,
  Col,
  Icon,
  Textarea,
  Dropdown,
  Loading,
} from "@dws/dws-styleguide";
import Moment from "react-moment";
import ConfirmCommentActionModal from "./ConfirmCommentActionModal";
import { editActionComment } from "../../hooks/editActionComment";
import { deleteActionComment } from "../../hooks/deleteActionComment";

const ActionDetailComment = ({ refreshData, item, isUser, authenticationData }) => {
  const { i18n, t } = useTranslation();
  const isEnUs = navigator.language === "en-US" && i18n.language === "en";
  const [isEdit, setIsEdit] = useState(false);
  const [commentText, setCommentText] = useState(item.text);
  const [fileUrl, setFileUrl] = useState(item.fileUrl);
  const [loading, setLoading] = useState(false);
  const dropdownOptions = {
    constrainWidth: true,
    autoTrigger: true,
    closeOnClick: true,
    alignment: "right",
  };

  const editComment = async () => {
    setLoading(true);
    const payload = {
      cod_action_comment: item.commentCode,
      str_text: commentText,
    };

    item.edit = true;

    const response = await editActionComment(payload, authenticationData);
    handleResponse(response, "editing_comment");
  };

  const deleteComment = async () => {
    setLoading(true);
    const payload = {
      delete: true,
      cod_action_comment: item.commentCode,
    };

    item.delete = true;

    const response = await deleteActionComment(payload, authenticationData);
    handleResponse(response, "deleting_comment");
  };

  const handleResponse = async (response, message) => {
    await refreshData(item);
    if (response.type === "success") {
      M.toast({
        html: t(`actions.success_${message}`),
        classes: "toast-success",
        displayLength: 4000,
      });
    } else {
      M.toast({
        html: t(`actions.error_${message}`),
        classes: "toast-warning",
        displayLength: 4000,
      });
    }

    setLoading(false);
    setIsEdit(false);
  };

  return (
    <div
      id={item.commentCode}
      className={`action-detail-comment ${isUser ? "is-me" : ""}`}
      key={item.commentCode}>
      <div className="action-detail-comment-content">
        <Row className="user-row">
          {/* TODO: add user avatar url */}
          <img className="user-avatar"></img>
          <Col className="comment-header">
            <div className="ch-username valign-wrapper">{item.username}</div>
            {isUser ? (
              <>
                {!isEdit ? (
                  <Dropdown
                    options={dropdownOptions}
                    trigger={<Icon className="icon">more_horiz</Icon>}>
                    <div
                      onClick={() => setIsEdit(true)}
                      className="comment-dropdown-option">
                      {t("general.edit")}
                    </div>
                    <ConfirmCommentActionModal callback={deleteComment} />
                  </Dropdown>
                ) : (
                  <Icon tiny onClick={() => editComment()} className="icon">
                    border_color
                  </Icon>
                )}
              </>
            ) : null}
          </Col>
        </Row>
        <Row className="mb-2 text-row">
          {loading ? (
            <Loading color={'light-purple'} className="loading" />
          ) : (
            <>
              {isEdit ? (
                <Textarea
                  onChange={(e) => {
                    setCommentText(e.target.value);
                  }}
                  value={commentText}
                />
              ) : (
                <div className="comment-text">
                  {fileUrl && <img src={fileUrl} />}
                  {commentText}
                </div>
              )}
            </>
          )}
        </Row>
        <Row className="date-wrapper">
          <Moment
            date={new Date(item.insertDate)}
            format={`${isEnUs ? "MM/DD" : "DD/MM"}/YYYY`}
            locale={i18n.language}
          />
          <Moment
            date={new Date(item.insertDate)}
            format={`hh:mm`}
            locale={i18n.language}
          />
        </Row>
      </div>
    </div>
  );
};

export default ActionDetailComment;
