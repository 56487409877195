import React from "react";
import { useTranslation } from "@dws/dws-profile";
import { Row, Col } from "@dws/dws-styleguide";
import Status from "../Actions/Status";

const ActionDetailStatus = ({ action, updateActionStatus }) => {
  const { t } = useTranslation();
  const actionsAllowed = action?.canChangeTo?.map((item) => item.statusCode);
  const statuses = [
    {
      id: 1,
      title: t("actions.not_started"),
    },
    {
      id: 2,
      title: t("actions.in_progress"),
    },
    {
      id: 3,
      title: t("actions.requested_to_close"),
    },
    {
      id: 4,
      title: t("actions.canceled"),
    },
    {
      id: 7,
      title: t("actions.closed"),
    },
  ];

  return (
    <Row className="action-detail-status">
      <Col m={12} s={12}>
        <div className="is-flex">
          <div className="statuses">
            {statuses.map((item) => (
              <Status
                key={item.id}
                label={item.title}
                disabled={!actionsAllowed.includes(item.id)}
                current={action?.statusDisregardingDeadlineDescription}
                status
                onClick={() =>
                  actionsAllowed.includes(item.id)
                    ? updateActionStatus(action.code, item.id)
                    : null
                }
              />
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ActionDetailStatus;
