import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { IAL_API_URL } from '../config';

const IAL_UPDATE_ACTION_STATUS = IAL_API_URL + '/problem-solving/change-action-status';

export async function putActionStatus(payload, authenticationData) {
  const config = getHeaders(authenticationData?.accessToken);
  try {
    return await axios.put(IAL_UPDATE_ACTION_STATUS, payload, config);
  } catch (e) {
    console.log('error when updating action status: ', e);
    return false;
  };
};
