import React, { useState } from "react";
import ActionDetailComment from "./ActionDetailComment";
import ActionDetailNewComment from "./ActionDetailNewComment";
import { Button, Icon } from "@dws/dws-styleguide";
import { getAccount } from "../../helpers/auth";
import { useTranslation } from "@dws/dws-profile";

const ActionDetailComments = ({ action, authenticationData }) => {
  const { t } = useTranslation();
  const { username } = getAccount();
  const [comments, setComments] = useState(action.interactionLogActionComments);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const newComment = () => {
    setIsNew(true);
    window.setTimeout(function () {
      document.getElementById("new-comment-input").focus();
    }, 0);
  };

  const refreshComments = (comment) => {
    setIsNew(false);
    if (comment) {
      if (comment.delete) {
        setComments(comments.filter(c => c.commentCode !== comment.commentCode));
      } else if (comment.edit) {
        setComments(comments.map(c => c.commentCode === comment.commentCode ? comment : c));
      } else {
        setComments([...comments, comment]);
      }
    }
  };

  return (
    <div className="action-detail-comments">
      <div className="comments-header right-align">
        <Button
          onClick={() => newComment()}
          flat
          className="btn btn-transparent">
          <div className="is-flex-row">
            <Icon className="icon">add_circle_outline</Icon>{" "}
            <span className="ml-2">{t("actions.add_comment")}</span>
          </div>
        </Button>
      </div>
      <div className="comments-wrapper">
        <div className="wrappers-header">
          <div className="comments-title">{t("actions.comments")}</div>
          <div className="expand-btn">
            <Icon onClick={() => setIsExpanded(!isExpanded)} className="icon">
              {isExpanded ? "expand_more" : "expand_less"}
            </Icon>
          </div>
        </div>
        <div
          style={{ height: isExpanded ? 0 : "auto" }}
          className="comment-table">
          <ActionDetailNewComment
            refreshData={refreshComments}
            setIsNew={setIsNew}
            hidden={!isNew}
            actionCode={action.code}
            username={action.responsibleUsername}
            authenticationData={authenticationData}
          />
          {comments.map((item) => (
            <ActionDetailComment
              key={item.commentCode}
              item={item}
              isUser={username?.toLowerCase() === item.email?.toLowerCase()}
              actionCode={action.code}
              refreshData={refreshComments}
              authenticationData={authenticationData}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionDetailComments;
